export enum Permission {
    VIEW_USERS = 'viewUsers',
    VIEW_USER = 'viewUser',
    CREATE_USER = 'createUser',
    EDIT_USER = 'editUser',
    VIEW_ROLE = 'viewRole',
    CREATE_ROLE = 'createRole',
    EDIT_ROLE = 'editRole',
    ASSIGN_ROLE_TO_USER = 'assignRoleToUser',
    ASSIGN_PERMISSION_TO_ROLE = 'assignPermissionToRole',
    VIEW_SCRIPTS = 'viewScripts',
    VIEW_SCRIPT = 'viewScript',
    CREATE_SCRIPT = 'createScript',
    EDIT_SCRIPT = 'editScript',
    VIEW_APITOKENS = 'viewApiTokens',
    VIEW_APITOKEN = 'viewApiToken',
    CREATE_APITOKEN = 'createApiToken',
    EDIT_APITOKEN = 'editApiToken',
    VIEW_REQUESTS = 'viewRequests',
    EDIT_REQUEST = 'editRequest',
}
