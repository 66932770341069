import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { beforeEach } from '@/router/routeGuards';
import AppTemplate from '@/views/templates/AppTemplate.vue';
import ConfigTemplate from '@/views/templates/ConfigTemplate.vue';
import ApiTokenRoutes from '@/router/modules/apiToken';
import ConfigurationPage from '@/views/ConfigurationPage.vue';
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import PermissionPage from '@/views/pages/permission/PermissionPage.vue';
import RequestRoutes from '@/router/modules/request';
import RoleRoutes from '@/router/modules/role';
import ScriptRoutes from '@/router/modules/script';
import UserRoutes from '@/router/modules/user';

import { AuthActions } from '@/definitions';

const routes: RouteRecordRaw[] = [
    {
        name: 'Login',
        path: '/login',
        component: LoginPage,
    },
    {
        name: 'Logout',
        path: '/logout',
        component: LoginPage,
        beforeEnter: async () => {
            await store.dispatch(AuthActions.LOGOUT);
            return true;
        },
    },
    {
        name: 'AppTemplate',
        path: '/',
        component: AppTemplate,
        children: [
            {
                name: 'Home',
                path: '',
                component: HomePage,
            },
            {
                path: '/:catchAll(.*)',
                component: NotFoundPage,
            },
        ],
    },
    {
        name: 'ConfigTemplate',
        path: '/config',
        component: ConfigTemplate,
        children: [
            {
                name: 'Configurations',
                path: '',
                component: ConfigurationPage,
            },
            {
                name: 'Permissions',
                path: 'permissions',
                component: PermissionPage,
            },
            ApiTokenRoutes,
            RequestRoutes,
            RoleRoutes,
            ScriptRoutes,
            UserRoutes,
            {
                path: '/:catchAll(.*)',
                component: NotFoundPage,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(beforeEach);

export default router;
